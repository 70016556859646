import Rest from '@/services/Rest';

/**
 * @typedef {EmailTotalGraphService}
 */
export default class EmailTotalGraphService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'email/get/total'


}